import Vue from 'vue'
import VueRouter from 'vue-router'

// LAYOUTS
import HomeLayout from '../layouts/HomeLayout.vue'

// VIEWS
import StorePage from '../views/StorePage.vue'
import DetailsPage from '../views/DetailsPage.vue'
import CartPage from '@/views/CartPage.vue'
import PaymentPage from '@/views/PaymentPage.vue'
import CategoryPage from '@/views/CategoryPage.vue'
import OrderPage from '@/views/OrderPage.vue'
Vue.use(VueRouter)

var routes = []

if(window.location.hostname === 'cintosjulioalmeida.com' || window.location.hostname === 'teste.cintosjulioalmeida.com' || window.location.hostname === 'www.cintosjulioalmeida.com' || window.location.hostname === 'www.teste.cintosjulioalmeida.com'){
  console.log('> NÃO TEM URL PRÓPRIA')
  window.localStorage.setItem('urlStore', 'cintosjulioalmeida.com')
  routes = [
    { 
      path: '/', 
      component: HomeLayout, 
      children: [
        { 
          path: '/:storeSlug', 
          component: StorePage
        },
        { 
          path: '/:storeSlug/product/:id', 
          component: DetailsPage
        },
        { 
          path: '/:storeSlug/cart', 
          component: CartPage
        },
        { 
          path: '/:storeSlug/category/:categorySlug', 
          component: CategoryPage
        },
        { 
          path: '/:storeSlug/payment', 
          component: PaymentPage
        },
        { 
          path: '/:storeSlug/order/:token', 
          component: OrderPage
        },
        
      ] 
    }
  ]
}else{
  console.log('> TEM URL PRÓPRIA')
  window.localStorage.setItem('urlStore', window.location.hostname)
  routes = [
    { 
      path: '/', 
      component: HomeLayout, 
      children: [
        { 
          path: '/', 
          component: StorePage
        },
        { 
          path: '/product/:id', 
          component: DetailsPage
        },
        { 
          path: '/cart', 
          component: CartPage
        },
        { 
          path: '/category/:categorySlug', 
          component: CategoryPage
        },
        { 
          path: '/payment', 
          component: PaymentPage
        },
        { 
          path: '/order/:token', 
          component: OrderPage
        },
        
      ] 
    }
  ]
}


const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
