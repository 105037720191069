<template>
    <v-app>
        <Navbar :isMobile="$store.getters.isMobile" :productsInCart="productsInCart" />

        <v-container>
            <v-row>
                <v-col cols="12" md="8">
                    <div v-if="step === 1" class="card-infos">
                        <v-row>
                            <v-col>
                                <h3>Informações pessoais</h3>
                                <hr>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" md="6">
                                <input type="text" class="input-default" placeholder="Nome completo"
                                    v-model="data.personal.name">
                            </v-col>
                            <v-col cols="12" md="6">
                                <input type="text" class="input-default" placeholder="CPF" v-mask="['###.###.###-##']"
                                    v-model="data.personal.cpf">
                            </v-col>
                            <v-col cols="12" md="6">
                                <input type="text" class="input-default" placeholder="Telefone"
                                    v-mask="['(##) #####-####']" v-model="data.personal.phone">
                            </v-col>
                            <v-col cols="12" md="6">
                                <input type="email" class="input-default" placeholder="Email"
                                    v-model="data.personal.email">
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <h3 v-if="shippingSelected.name != 'RETIRAR'">Endereço de entrega</h3>
                                <h3 v-if="shippingSelected.name == 'RETIRAR'">Endereço de cobrança</h3>
                                <hr>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12">
                                <input type="text" class="input-default" placeholder="CEP" @keyup="searchCep()"
                                    ref="cep" v-model="data.address.cep" v-mask="['#####-###']">
                            </v-col>
                            <v-col cols="8" md="9">
                                <input type="text" class="input-default" placeholder="Endereço" ref="street"
                                    v-model="data.address.street">
                            </v-col>
                            <v-col cols="4" md="3">
                                <input type="number" class="input-default" placeholder="N°" ref="number"
                                    v-model="data.address.number">
                            </v-col>
                            <v-col cols="12" md="12">
                                <input type="text" class="input-default" placeholder="Complemento" ref="complement"
                                    v-model="data.address.complement">
                            </v-col>
                            <v-col cols="12" md="6">
                                <input type="text" class="input-default" placeholder="Cidade"
                                    v-model="data.address.city">
                            </v-col>
                            <v-col cols="12" md="6">
                                <input type="text" class="input-default" placeholder="Estado"
                                    v-model="data.address.state">
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <button class="btn-default" @click="nextStep">Próximo</button>
                            </v-col>
                        </v-row>
                    </div>

                    <div v-if="step === 2" class="card-infos">
                        <v-row>
                            <v-col>
                                <h3>Revisão dos Dados</h3>
                                <hr>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" md="6">
                                <p><strong>Nome completo:</strong> {{ data.personal.name }}</p>
                            </v-col>
                            <v-col cols="12" md="6">
                                <p><strong>CPF:</strong> {{ data.personal.cpf }}</p>
                            </v-col>
                            <v-col cols="12" md="6">
                                <p><strong>Telefone:</strong> {{ data.personal.phone }}</p>
                            </v-col>
                            <v-col cols="12" md="6">
                                <p><strong>Email:</strong> {{ data.personal.email }}</p>
                            </v-col>
                            <v-col cols="12">
                                <p><strong>CEP:</strong> {{ data.address.cep }}</p>
                            </v-col>
                            <v-col cols="8" md="9">
                                <p><strong>Endereço:</strong> {{ data.address.street }}</p>
                            </v-col>
                            <v-col cols="4" md="3">
                                <p><strong>N°:</strong> {{ data.address.number }}</p>
                            </v-col>
                            <v-col cols="12">
                                <p><strong>Complemento:</strong> {{ data.address.complement }}</p>
                            </v-col>
                            <v-col cols="12" md="6">
                                <p><strong>Cidade:</strong> {{ data.address.city }}</p>
                            </v-col>
                            <v-col cols="12" md="6">
                                <p><strong>Estado:</strong> {{ data.address.state }}</p>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="6">
                                <button class="btn-default" @click="previousStep">Voltar</button>
                            </v-col>
                            <v-col cols="6">
                                <button class="btn-default" @click="nextStep">Continuar</button>
                            </v-col>
                        </v-row>
                    </div>

                    <div v-if="step === 3" class="card-infos">
                        <v-row>
                            <v-col>
                                <h3>Pagamento</h3>
                                <hr>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" md="12">
                                <div class="form-item">
                                    <label for="payment_method">Forma de Pagamento</label>
                                    <select name="" id="payment_method" class="input-default"
                                        v-model="paymentData.payment_method">
                                        <option value="" disabled>Selecione</option>
                                        <option value="PIX">PIX</option>
                                        <option value="CREDIT_CARD_MP">Cartão de Crédito</option>
                                    </select>
                                </div>
                            </v-col>
                        </v-row>
                        <v-row v-if="paymentData.payment_method == 'CREDIT_CARD_MP'">
                            <div id="cardPaymentBrick_container"></div>
                        </v-row>
                    </div>
                </v-col>
                <v-col>
                    <div class="summary">
                        <v-row>
                            <v-col cols="12">
                                <div class="products">
                                    <v-row class="product" v-for="(p, index) in products" :key="index">
                                        <v-col cols="4" md="3" class="image">
                                            <img :src="p.product.images[0].src" style="width: 100%;" alt="">
                                        </v-col>
                                        <v-col class="desc">
                                            <v-row>
                                                <v-col cols="12" class="name">
                                                    <h3 style="font-size: 15px">{{ p.qnt }} x {{ p.product.name }} ({{
                                                        p.variantSelected.value }})}}</h3>
                                                </v-col>
                                                <v-col cols="6" md="8" class="price">
                                                    <h3>{{ parseFloat(p.sale_price).toLocaleString('pt-BR', {
                                                        style:
                                                            'currency', currency: 'BRL'
                                                    }) }}</h3>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                    </v-row>
                                </div>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <h2>Resumo</h2>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <h3>Subtotal</h3>
                            </v-col>
                            <v-col style="text-align: right;">
                                <h3>{{ products.reduce((acc, p) => acc + (p.sale_price * p.qnt),
                                    0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) }}</h3>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <h3>Frete</h3>
                            </v-col>
                            <v-col style="text-align: right;">
                                <h3>{{ shipping.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) }}</h3>
                                <small v-if="shippingSelected.name != 'RETIRAR'">{{ shippingSelected ?
                                    shippingSelected.name : '' }} - {{ shippingSelected ? shippingSelected.deadline : ''
                                    }} Dias</small>
                                <small v-if="shippingSelected.name == 'RETIRAR'">RETIRAR NA LOJA</small>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <hr>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <h3>Total</h3>
                            </v-col>
                            <v-col style="text-align: right;">
                                <h3>{{ (((products.reduce((acc, p) => acc + (p.sale_price * p.qnt), 0)) +
                                    shipping)).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) }}</h3>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col v-if="step === 3 && paymentData.payment_method == 'PIX'">
                                <button :class="{ 'btn-default': true, 'disabled': true }"
                                    v-if="loading">Aguarde...</button>
                                <button :class="{ 'btn-default': true, 'disabled': false }" @click="pay"
                                    v-else>Finalizar
                                    Compra</button>
                            </v-col>
                        </v-row>
                    </div>
                </v-col>
            </v-row>
        </v-container>

        <v-snackbar v-model="snackbar.show" :color="snackbar.color" :timeout="snackbar.timeout" top right>
            {{ snackbar.message }}
        </v-snackbar>

        <Footer :isMobile="$store.getters.isMobile" />
    </v-app>
</template>

<script>
import Notice from '@/components/Notice.vue'
import Navbar from '@/components/Navbar.vue'
import Footer from '@/components/Footer.vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { mask } from 'vue-the-mask'
import axios from 'axios'
import config from '@/store/config'
const api = axios.create({
    baseURL: config.baseURL
})

import { loadMercadoPago } from "@mercadopago/sdk-js";

async function initializeMercadoPago() {
    await loadMercadoPago();
    if (process.env.VUE_APP_SANDBOX == 'true') {
        console.log('sandbox')
        return new window.MercadoPago("TEST-87f8073f-098f-4ccb-b001-fa5e0c8e7a2e");
    } else {
        console.log('produção')
        return new window.MercadoPago("APP_USR-01995e68-d099-4788-b512-0b4d6680c78f");
    }
}

function isValidCPF(cpf) {
    if (typeof cpf !== "string") return false
    cpf = cpf.replace(/[\s.-]*/igm, '')
    if (
        !cpf ||
        cpf.length != 11 ||
        cpf == "00000000000" ||
        cpf == "11111111111" ||
        cpf == "22222222222" ||
        cpf == "33333333333" ||
        cpf == "44444444444" ||
        cpf == "55555555555" ||
        cpf == "66666666666" ||
        cpf == "77777777777" ||
        cpf == "88888888888" ||
        cpf == "99999999999"
    ) {
        return false
    }
    var soma = 0
    var resto
    for (var i = 1; i <= 9; i++)
        soma = soma + parseInt(cpf.substring(i - 1, i)) * (11 - i)
    resto = (soma * 10) % 11
    if ((resto == 10) || (resto == 11)) resto = 0
    if (resto != parseInt(cpf.substring(9, 10))) return false
    soma = 0
    for (var i = 1; i <= 10; i++)
        soma = soma + parseInt(cpf.substring(i - 1, i)) * (12 - i);
    resto = (soma * 10) % 11;
    if ((resto == 10) || (resto == 11)) resto = 0;
    if (resto != parseInt(cpf.substring(10, 11))) return false
    return true
}

export default {
    name: 'PaymentPage',
    metaInfo() {
        return {
            title: `${this.storeData.name} - Pagamento`
        }
    },
    directives: {
        mask
    },
    components: {
        Notice,
        Navbar,
        Footer,
        FontAwesomeIcon
    },
    data() {
        return {
            storeData: null,
            products: localStorage.getItem('cart') ? JSON.parse(localStorage.getItem('cart')) : [],
            btnDisabled: true,
            shipping: 0,
            productsInCart: 0,
            loading: false,
            cepResult: false,
            shippingSelected: null,
            paymentData: {
                payment_method: '',
                name: '',
                holder: '',
                cpf_cc: '',
                birth: '',
                card_number: '',
                expiration_date: '',
                cvv: '',
                installments: ''
            },
            gettingInstallments: false,
            installments: [],
            data: {
                personal: {
                    name: '',
                    cpf: '',
                    email: '',
                    phone: ''
                },
                address: {
                    cep: '',
                    street: '',
                    number: '',
                    city: '',
                    state: '',
                    complement: ''
                }
            },
            step: 1,
            snackbar: {
                show: false,
                message: '',
                color: 'error',
                timeout: 3000
            },
            mp: null // adicionar a propriedade mp aqui
        }
    },
    watch: {
        'paymentData.payment_method': async function () {
            if (this.paymentData.payment_method == 'CREDIT_CARD_MP') {
                setTimeout(() => {
                    this.initMP()
                }, 500);
            }
        }
    },
    async created() {
        this.mp = await initializeMercadoPago(); // inicializar o MercadoPago aqui
        await this.init();
    },
    methods: {
        async init() {
            window.fbq('track', 'AddPaymentInfo');
            this.storeData = await this.$store.dispatch('getStore', this.$route.params.storeSlug == undefined ? localStorage.urlStore : this.$route.params.storeSlug)
            this.shippingSelected = localStorage.getItem('shipping') ? JSON.parse(localStorage.getItem('shipping')) : null
            this.shipping = this.shippingSelected ? this.shippingSelected.price : 0
            this.data.address.cep = this.shippingSelected.cep ? this.shippingSelected.cep : ''
            await this.searchCep()
            await this.verifyQntCart()
        },
        async verifyQntCart() {
            this.productsInCart = localStorage.getItem('cart') ? JSON.parse(localStorage.getItem('cart')).length : 0
            this.productsInCart > 0 ? this.btnDisabled = false : this.btnDisabled = true
        },
        async removeProduct(index) {
            this.products.splice(index, 1);
            localStorage.setItem('cart', JSON.stringify(this.products));
            this.verifyQntCart();
        },
        async findCep(cep) {
            try {
                let response = await axios.get(`https://viacep.com.br/ws/${cep}/json/`)
                return response.data
            } catch (error) {
                console.log(error)
            }
        },
        async searchCep() {
            let cep = this.data.address.cep.replace('-', '')
            if (cep.length == 8) {
                let result = await this.findCep(cep)
                if (result.erro) {
                    this.showSnackbar('CEP não encontrado. Insira o endereço manualmente.')
                    this.cepResult = true
                    this.$nextTick(() => this.$refs.street.focus())
                    this.data.address.street = ''
                    this.data.address.city = ''
                    this.data.address.state = ''
                    this.data.address.complement = ''
                } else {
                    this.cepResult = true
                    this.$nextTick(() => this.$refs.number.focus())
                    this.data.address.street = result.logradouro
                    this.data.address.city = result.localidade
                    this.data.address.state = result.uf
                }
            }
        },
        async verifyWhatsApp() {
            const formattedPhone = this.data.personal.phone.replace(/\D/g, '');
            const ddiPhone = `55${formattedPhone}`;
            try {
                const response = await axios.post('https://api.gestaovix.com.br/chat/whatsappNumbers/AbC789c74637f30120344e90f7b30b1c309265', {
                    numbers: [ddiPhone]
                }, {
                    headers: {
                        'Content-Type': 'application/json',
                        apikey: '1269140ae2651fec1b4e14cd6ad9dbba'
                    }
                });
                const numberInfo = response.data[0];

                if (numberInfo && numberInfo.exists) {
                    return numberInfo.exists;
                } else {
                    return false;
                }
            } catch (error) {
                console.log(error);
                return false;
            }
        },
        async nextStep() {
            if (this.step === 1) {
                if (this.data.personal.name.trim().split(' ').length < 2) {
                    this.showSnackbar('Por favor, insira o nome completo.');
                    return;
                }
                if (!isValidCPF(this.data.personal.cpf)) {
                    this.showSnackbar('CPF inválido.');
                    return;
                }
                if (!this.data.personal.email) {
                    this.showSnackbar('Por favor, insira o email.');
                    return;
                }
                if (!this.data.personal.phone) {
                    this.showSnackbar('Por favor, insira o telefone.');
                    return;
                }
                if (!this.data.address.cep) {
                    this.showSnackbar('Por favor, insira o CEP.');
                    return;
                }
                if (!this.data.address.street) {
                    this.showSnackbar('Por favor, insira o endereço.');
                    return;
                }
                if (!this.data.address.number) {
                    this.showSnackbar('Por favor, insira o número.');
                    return;
                }
                if (!this.data.address.complement) {
                    this.showSnackbar('Por favor, insira o complemento.');
                    return;
                }
                if (!this.data.address.city) {
                    this.showSnackbar('Por favor, insira a cidade.');
                    return;
                }
                if (!this.data.address.state) {
                    this.showSnackbar('Por favor, insira o estado.');
                    return;
                }

                const hasWhatsApp = await this.verifyWhatsApp();
                if (!hasWhatsApp) {
                    this.showSnackbar('O número não contém WhatsApp.');
                    return;
                }
            }
            this.step++;
        },
        previousStep() {
            this.step--;
        },
        showSnackbar(message) {
            this.snackbar.message = message;
            this.snackbar.show = true;
        },
        async getInstallments() {
            console.log(this.paymentData.card_number.length)
            if (this.paymentData.card_number.length >= 19) {
                this.gettingInstallments = true
                let data = {
                    amount: (((this.products.reduce((acc, p) => acc + (p.sale_price * p.qnt), 0)) + this.shipping)).toFixed(2),
                    card_number: this.paymentData.card_number
                }
                console.log(data)
                try {
                    let response = await api.post('/order/credit_card/installments', data, await this.$store.getters.getConfig)
                    if (response.data.error) {
                        this.showSnackbar(response.data.message);
                        this.gettingInstallments = false
                        return
                    }
                    this.installments = response.data.installments
                    this.gettingInstallments = false

                } catch (error) {
                    this.gettingInstallments = false
                    console.log(error)
                }
            } else {
                this.gettingInstallments = false
            }
        },
        async initMP() {
            let app = this
            const bricksBuilder = this.mp.bricks();
            let price = (((this.products.reduce((acc, p) => acc + (p.sale_price * p.qnt), 0)) + this.shipping))
            console.log(price)
            const renderCardPaymentBrick = async (bricksBuilder) => {
                const settings = {
                    initialization: {
                        amount: parseFloat(price).toFixed(2), // valor total a ser pago
                        payer: {
                            email: "",
                        },
                    },
                    customization: {
                        visual: {
                            hideFormTitle: true,
                            style: {
                                customVariables: {
                                    theme: 'default', // | 'dark' | 'bootstrap' | 'flat'
                                }
                            }
                        },
                        paymentMethods: {
                            types: {
                                excluded: ['debit_card']
                            },
                            maxInstallments: 12,
                        }
                    },
                    callbacks: {
                        onReady: () => {
                            // callback chamado quando o Brick estiver pronto
                        },
                        onSubmit: (cardFormData) => {
                            app.pay(cardFormData)
                        },
                        onError: (error) => {
                            // callback chamado para todos os casos de erro do Brick
                            this.showSnackbar(error);
                        },
                    },
                };
                window.cardPaymentBrickController = await bricksBuilder.create('cardPayment', 'cardPaymentBrick_container', settings);
            };
            renderCardPaymentBrick(bricksBuilder);

        },
        async pay(mpData = null) {
            window.fbq('track', 'Purchase');
            try {
                if (this.data.personal.name == '') {
                    this.showSnackbar('Insira o nome');
                    return
                }

                if (this.data.personal.name.split(' ').length < 2) {
                    this.showSnackbar('Insira o nome completo');
                    return
                }

                if (this.data.personal.cpf == '') {
                    this.showSnackbar('Insira o CPF');
                    return
                }

                if (!isValidCPF(this.data.personal.cpf)) {
                    this.showSnackbar('CPF inválido');
                    return
                }

                if (this.data.personal.email == '') {
                    this.showSnackbar('Insira o email');
                    return
                }

                if (this.data.personal.phone == '') {
                    this.showSnackbar('Insira o telefone');
                    return
                }

                if (this.data.address.cep == '') {
                    this.showSnackbar('Insira o CEP');
                    return
                }

                if (this.data.address.street == '') {
                    this.showSnackbar('Insira o endereço');
                    return
                }

                if (this.data.address.number == '') {
                    this.showSnackbar('Insira o número');
                    return
                }

                if (this.data.address.complement == '') {
                    this.showSnackbar('Insira o complemento');
                    return
                }

                if (this.data.address.city == '') {
                    this.showSnackbar('Insira a cidade');
                    return
                }

                if (this.data.address.state == '') {
                    this.showSnackbar('Insira o estado');
                    return
                }

                if (this.paymentData.payment_method == '') {
                    this.showSnackbar('Selecione a forma de pagamento');
                    return
                }

                let payment_token = ''
                if (this.paymentData.payment_method == 'CREDIT_CARD') {
                    if (this.paymentData.holder == '') {
                        this.showSnackbar('Insira o nome do titular');
                        return
                    }
                    if (this.paymentData.card_number.length < 19) {
                        this.showSnackbar('Insira o número do cartão');
                        return
                    }
                    if (this.paymentData.cpf_cc == '') {
                        this.showSnackbar('Insira o CPF do titular');
                        return
                    }
                    if (this.paymentData.expiration_date.length < 5) {
                        this.showSnackbar('Insira a data de expiração');
                        return
                    }
                    if (this.paymentData.cvv.length < 3) {
                        this.showSnackbar('Insira o CVV');
                        return
                    }
                    if (this.paymentData.installments == '') {
                        this.showSnackbar('Selecione as parcelas');
                        return
                    }

                    //GET TOKEN
                    let data = {
                        card_number: this.paymentData.card_number.replace(/\D/g, ''),
                        card_expiration_date: this.paymentData.expiration_date,
                        card_cvv: this.paymentData.cvv
                    }
                    try {
                        let response = await api.post('/order/credit_card/token', data, await this.$store.getters.getConfig)
                        if (response.data.error) {
                            this.showSnackbar(response.data.message);
                            return
                        }
                        payment_token = response.data.payment_token
                    } catch (error) {
                        console.log(error)
                    }
                }

                this.loading = true

                let products = this.products.map(p => {
                    return {
                        id_product: p.product.id,
                        variant_id: p.variantSelected.id,
                        quantity: p.qnt,
                        cost_price: p.product.costPrice,
                        price: p.sale_price
                    }
                })


                let dataIns = {
                    "id_store": this.storeData.id,
                    "costumer": {
                        "name": this.data.personal.name,
                        "cpf": this.data.personal.cpf,
                        "email": this.data.personal.email,
                        "phone": this.data.personal.phone,
                        "address": this.data.address.street,
                        "neighborhood": this.data.address.complement,
                        "number": this.data.address.number,
                        "city": this.data.address.city,
                        "state": this.data.address.state,
                        "zip_code": this.data.address.cep.replace(/\D/g, '')
                    },
                    "payment": {
                        "birth": this.paymentData.birth,
                        "cpf_cc": this.paymentData.cpf_cc,
                        "method": this.paymentData.payment_method,
                        "installments": this.paymentData.installments,
                        "shipping": this.shipping,
                        "shipping_type": this.shippingSelected ? this.shippingSelected.name : '',
                        mpData
                    },
                    products
                }

                console.log(dataIns)

                //INSERT ORDER
                try {
                    let response = await api.post('/orders', dataIns, await this.$store.getters.getConfig)
                    if (response.data.error) {
                        this.showSnackbar(response.data.message);
                        this.loading = false
                        return
                    }
                    console.log(response.data)

                    localStorage.removeItem('cart')
                    this.$router.push(this.$route.params.storeSlug == undefined ? `/order/${response.data.order.token}` : `/${this.storeData.slug}/order/${response.data.order.token}`)
                    this.loading = false
                    window.fbq('track', 'Purchase', { value: response.data.order.total, currency: 'BRL' });
                } catch (error) {
                    console.log(error)
                    this.loading = false
                }
            } catch (error) {
                console.log(error)
                this.loading = false
            }
        }
    }
}
</script>




<style scoped>
* {
    font-family: "Exo", sans-serif;
}

::placeholder {
    color: #00000066;
    opacity: 1;
    /* Firefox */
}

hr {
    border: 1px solid #0000001A;
    margin-top: 5px;
    margin-bottom: 5px;
}

h2 {
    font-size: 2.5rem;
    font-weight: 700;
}

.btn-default {
    width: 100%;
    background-color: #000;
    color: #fff;
    border-radius: 62px;
    padding: 10px;
}

.btn-default.disabled {
    background-color: #00000066;
}

.input-default {
    width: 100%;
    padding: 10px;
    padding-left: 15px;
    border: 1px solid #F0F0F0;
    background-color: #F0F0F0;
    border-radius: 50px;
}


.input-qnt {
    border: 1px solid #F0F0F0;
    background-color: #F0F0F0;
    border-radius: 50px;
    margin-right: 10px;
}

.input-qnt .remove img,
.input-qnt .add img {
    width: 20px;
    cursor: pointer;
}

.input-qnt .qnt {
    text-align: center;
    font-size: 1.5rem;
}

.input-qnt .add img {
    float: right;
    margin-top: 8px;
    margin-right: 10px;
}

.input-qnt .remove img {
    float: left;
    margin-top: 8px;
    margin-left: 10px;
}



.card-infos {
    border-radius: 20px;
    border: 1px solid #0000001A;
    padding: 20px;
    margin-bottom: 20px;
}

.summary {
    border-radius: 20px;
    border: 1px solid #0000001A;
    padding: 20px;
}
</style>
